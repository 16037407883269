export const subscriptionsDetails = {
  daily: {
    cost: 50,
    duration: "1 Day",
    expiry: "3/11/2024",
  },
  weekly: {
    cost: 300,
    duration: "1 Week",
    expiry: "8/11/2024",
  },
  bi_weekly: {
    cost: 500,
    duration: "2 Weeks",
    expiry: "16/11/2024",
  },
  monthly: {
    cost: 900,
    duration: "1 Month",
    expiry: "2/12/2024",
  },
  custom: {
    cost: 50,
    duration: "1 Day",
    expiry: "3/11/2024",
  },
};

export const columns = [
  { id: "date_purchased", label: "Date", type: "Date" },
  { id: "expiry_date", label: "Expiry Date", type: "Date" },
  {
    id: "duration",
    label: "Duration",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "cost",
    label: "Cost",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "mpesa",
    label: "MPESA Code",
    // minWidth: 170,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];
