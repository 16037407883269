export const notification = {
  title: "Welcome to Verbot",
  subtitle: "Your journey to a happy and rewarding transcribing begins here!",
  sections: [
    {
      imgSrc: "https://picsum.photos/1000/300",
      text:
        "Verbot is a modern transcribing solution aiming to " +
        "enhance the transcribing work for all interested transcribers. The " +
        "developer of Verbot is an experienced transcriber, who understands the " +
        "transcription process better than other developers offering " +
        "transcription solutions.",
    },
    {
      imgSrc: null,
      text:
        "In transcription, the vast majority of a " +
        "transcriber’s work is “looking and claiming for jobs.” This model " +
        "doesn’t make any sense as this prime time can be utilized elsewhere " +
        "maybe cooking, watching movies or even doing some side hustle. " +
        "Transcribers all over have auto-refreshing extensions on their Google " +
        "Chrome. These extensions are also trying to solve the same problem as " +
        "Verbot, Simplifying the work of transcribers. It’s not doubt that modern " +
        "transcribers have been turned into bot-like humans, whose work is to " +
        "refresh their browsers endlessly and keep an open eye for new jobs. It’s " +
        "a sad state of affairs.",
    },
    {
      imgSrc: null,
      text:
        "We also understand that bots can increase the " +
        "amount of traffic being sent to the employers’ websites thus causing " +
        "system failure or poor performance of the target systems. To this effect, " +
        "we have enabled every user of our systems to set their preferred refresh " +
        "durations. One can set his bot to refresh after every custom amount of " +
        "time, say 3, 5 or 8 seconds. This will make your bot traffic look organic and " +
        "human-like, thus keeping our users safe from detection by the target " +
        "system(s).",
    },
    {
      imgSrc: null,
      text:
        "We have also enhanced the security of our bots so that they " +
        "remain undetectable by the target systems. In this regard, we have used " +
        "undetectable software in the making of our bots. Our users can be " +
        "guaranteed of security while they are suing our bots. Our systems will " +
        "be updated regularly to enhance this already enhanced security, and to " +
        "ensure that we’re always one step ahead of the target systems. We’re " +
        "very capable and committed to ensuring this security.",
    },
    {
      imgSrc: null,
      text:
        "Also of note is the charges that other bots are charging. Our systems will not " +
        "discriminate between editors or reviewers. All will be charged an " +
        "affordable fee, much lower than the current rates.",
    },
    {
      imgSrc: null,
      text: "Thank you for checking us out. Happy Transcribing!",
    },
  ],
};
