import React, { useEffect } from "react";
import Subscribe from "./Subscribe";
import PastSubscriptions from "./PastSubscriptions";
import { db } from "../../firebase/app";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { Card } from "@mui/material";

function SubscriptionsPage({ user, setUser }) {
  // Fetching user's subscriptions
  useEffect(() => {
    if (user) {
      fetchAllSubscriptions();
    }
  }, []);

  // Function to fetch all subscriptions of a certain user
  const fetchAllSubscriptions = () => {
    console.log("Fetching subs..");
    // Get reference to the subscriptions collection inside the users document
    const subscriptionsRef = collection(
      db,
      "users",
      `${user.id}`,
      "subscriptions"
    );

    // Fetch all documents in the subscriptions subcollection
    return getDocs(subscriptionsRef)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          console.log("Query Snapshot: ", querySnapshot.docs[0].data());
          const subs = querySnapshot.docs.map((doc) => {
            return { ...doc.data() };
          });
          console.log("Subs: ", subs);
          // Update the User global object with the subscriptions data fro db
          setUser((prevUser) => {
            return {
              ...prevUser,
              subscriptions: subs,
            };
          });
        }
      })
      .catch((error) => alert(error));
  };

  return (
    <div className="bg-mangenta-400 px-1 md:px-4 mx-auto">
      <Card>
        <Subscribe user={user} />
      </Card>
      <PastSubscriptions user={user} setUser={setUser} />
    </div>
  );
}

export default SubscriptionsPage;
