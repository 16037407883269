import React from "react";
import { notification } from "./data";
import Section from "./Section";
import PreviewNotification from "../preview/PreviewNotification";

function DetailedNotification({ showPreviews, setShowPreviews }) {
  // Sections.paragraphs of the full story
  const Sections = notification.sections.map((sectionData, index) => {
    const { imgSrc, text } = sectionData;

    return <Section key={index} imgSrc={imgSrc} text={text} />;
  });

  // Title and Subtitle for our notification
  const { title, subtitle } = notification;

  if (showPreviews) return;

  return (
    <div className="mx-2 space-y-2">
      <div className="text-xl space-y-2">
        <div className="font-bold">{title}</div>
        <div className="flex space-x-1">
          <div className="w-[2px] bg-yellow-400"></div>{" "}
          <div className="text-yellow-400">{subtitle}</div>
        </div>
      </div>

      {/* Details */}
      {Sections}

      {/* Notifications */}
      <div className="pt-4">
        <div className="text-blue-500 underline">Notifications</div>
        <div className="space-y-4">
          <PreviewNotification
            showPreviews={showPreviews}
            setShowPreviews={setShowPreviews}
          />
        </div>
      </div>
    </div>
  );
}

export default DetailedNotification;
