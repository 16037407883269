import React from "react";

function Section({ imgSrc, text }) {
  const imgOrNull = imgSrc ? <img src={imgSrc} /> : null;

  return (
    <div className="space-y-2">
      {/* Section Image. We don't display anything if imgSrc is not set to anything*/}
      {imgOrNull}

      {/* Section Text */}
      <div>{text}</div>
    </div>
  );
}

export default Section;
