import { Button } from "@mui/material";
import React from "react";

function SubscriptionType({
  subscriptionType,
  selectedSubscription,
  setSelectedSubscription,
}) {
  return (
    <Button
      // variant={
      //   selectedSubscription === subscriptionType ? "contained" : "outlined"
      // }
      variant={selectedSubscription === subscriptionType ? "text" : "text"}
      color={selectedSubscription === subscriptionType ? "success" : "info"}
      fullWidth
      sx={{ borderRadius: 0 }}
      onClick={() => setSelectedSubscription(subscriptionType)}
    >
      {subscriptionType === "bi_weekly" ? "BI WEEKLY" : subscriptionType}
    </Button>
  );
}

export default SubscriptionType;
