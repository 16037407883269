import React, { useState } from "react";
import SubscriptionDetails from "./SubscriptionDetails";
import { Card } from "@mui/material";
import SubscriptionType from "./SubscriptionType";
import { subscriptionsDetails } from "./data";
import axios from "axios";

function Subscribe({ user }) {
  const [selectedSubscription, setSelectedSubscription] = useState("bi_weekly");

  const subscriptionTypes = ["daily", "weekly", "bi_weekly", "custom"];

  const SubscriptionTypes = subscriptionTypes.map((subscriptionType, index) => {
    return (
      <SubscriptionType
        key={index}
        subscriptionType={subscriptionType}
        selectedSubscription={selectedSubscription}
        setSelectedSubscription={setSelectedSubscription}
      />
    );
  });

  // Handling buy subscription
  const handleBuySubscription = async () => {
    console.log(
      "STK Push Endpoint",
      process.env.REACT_APP_MPESA_PAYMENT_ENDPOINT
    );
    // We'll send the phone number as well as the cost of the subscription to the backend for processing
    const stk_response = await axios.post(
      process.env.REACT_APP_MPESA_PAYMENT_ENDPOINT,
      {
        phoneNumber: user?.phone,
        amount: subscriptionsDetails[selectedSubscription].cost.toString(),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  return (
    <div className="py-2" id="subscribe">
      <div className="font-bold md:w-2/3 mx-auto pb-4 px-1 md:px-0">
        Buy Subscription or if user has an active subscription, show
        subscription details
      </div>
      <div className="w-full md:w-2/3 mx-auto px-1 md:px-0">
        <Card elevation={4}>
          <div className="px-1 pt-1 pb-4 flex md:block justify-center space-x-4 md:space-x-0">
            {/* Subscriptions Types - Buttons */}
            <div className="block w-1/2 md:flex md:w-full">
              {SubscriptionTypes}
            </div>
            {/* More Subsricption Details */}
            <SubscriptionDetails selectedSubscription={selectedSubscription} />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Subscribe;
