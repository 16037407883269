import React from "react";
import { subscriptionsDetails } from "./data";
import { Button } from "@mui/material";

function SubscriptionDetails({ selectedSubscription, handleBuySubscription }) {
  return (
    <div className="w-1/2 md:w-full my-auto pt-4">
      {/* Expiry Date */}
      <div className="flex space-x-2">
        <div className="w-1/2 md:text-right">Cost:</div>
        <div className="w-1/2 font-bold">
          {subscriptionsDetails[selectedSubscription].cost}
        </div>
      </div>

      {/* Expiry Date */}
      <div className="flex space-x-2">
        <div className="w-1/2 md:text-right">Duration:</div>
        <div className="w-1/2 font-bold">
          {subscriptionsDetails[selectedSubscription].duration}
        </div>
      </div>

      {/* Expiry Date */}
      <div className="flex space-x-2">
        <div className="w-1/2 md:text-right">Expiry:</div>
        <div className="w-1/2 font-bold">
          {subscriptionsDetails[selectedSubscription].expiry}
        </div>
      </div>

      {/* Buy Subscription Button */}
      <div className="mt-2 mb-0 text-left md:text-center max-w-[200px] md:mx-auto">
        <Button
          variant="contained"
          color="success"
          fullWidth
          onClick={handleBuySubscription}
        >
          BUY
        </Button>
      </div>
    </div>
  );
}

export default SubscriptionDetails;
