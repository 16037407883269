import { Button } from "@mui/material";
import React from "react";
import Title from "./Title";
import Summary from "./Summary";
import { notification } from "../details/data";

function PreviewNotification({ showPreviews, setShowPreviews }) {
  // Preview Notification Title
  const { title } = notification;
  // Preview notification summary
  const summary =
    "Verbot is a modern transcribing solution aiming to enhance the transcribing work for all interested transcribers. " +
    "The developer of Verbot is an experienced transcriber, who understands the transcription process better than other " +
    "developers offering transcription solutions.";

  return (
    <div className="mx-2">
      {/* Notification's Title */}
      <Title title={title} />

      {/* Notification's Summary */}
      <Summary summary={summary} />
      {/* Read More  */}
      <div className="mt-2">
        <Button
          variant="outlined"
          color="info"
          size="small"
          onClick={() => setShowPreviews((prevValue) => !prevValue)}
        >
          Read More...
        </Button>
      </div>
    </div>
  );
}

export default PreviewNotification;
