import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import React, { useEffect, useState } from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, db, provider } from "../../firebase/app";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { userData } from "./data";

// This method will return the current date and time as a string in this format ("%d-%m-%Y %H:%M:%S")
const getDateTimeString = () => {
  const currentDate = new Date();

  // Get individual date components
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const year = currentDate.getFullYear();

  // Get individual time components
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  // Combine the parts into the desired format
  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
};

function Account({ user, setUser, setActiveNavLink, loadingUser }) {
  const navigate = useNavigate();

  // Add new user to db
  const addUser = (user) => {
    // Reference to users collection
    const usersCollectionRef = collection(db, "users");

    // Add a new user document to the users collection
    addDoc(usersCollectionRef, {
      ...userData,
      email: user.email,
      displayName: user.displayName,
      userId: user.uid,
      photoURL: user.photoURL,
      // This will help us give free access to first timers for the first n days of joining
      dateJoined: getDateTimeString(),
    })
      .then((newUserDocRef) => {
        // We get the data about new user from the database and set it to our
        getDoc(newUserDocRef).then((userDocSnapshot) => {
          if (userDocSnapshot.exists()) {
            // We then set the user state to this newly created user
            setUser({ ...userDocSnapshot.data(), id: userDocSnapshot.id });
            // Persist user in local storage
            localStorage.setItem(
              "user",
              JSON.stringify(userDocSnapshot.data())
            );
          }
        });
      })
      .catch((error) => {
        alert(
          "An error occured while creating your records. Please sign in again."
        );
        // We set the active link to the redirected home link
        setActiveNavLink("Account");
        // Navigate to the Home Page
        navigate("/account");
      });
  };

  // Get user from database
  const getUser = (userId) => {
    const usersCollectionRef = collection(db, "users");

    // Query the users collection for documents with matching userId
    const q = query(usersCollectionRef, where("userId", "==", userId));

    // Execute the query and handle the result using then()
    return getDocs(q)
      .then((querySnapshot) => {
        // Check if any documents match the query
        if (!querySnapshot.empty) {
          // User with the provided userId exists
          // We then set the user state to this existing version from the db. We set it to the first matching document's data
          setUser({
            ...querySnapshot.docs[0].data(),
            id: querySnapshot.docs[0].id,
          });
          // Persist user in local storage
          localStorage.setItem(
            "user",
            JSON.stringify(querySnapshot.docs[0].data())
          );
          // return true;
        }
      })
      .catch((error) => {
        alert(
          "An error occured while getting your data. Please sign in again."
        );
        // We set the active link to the account page to allow user to retry signing in
        setActiveNavLink("Account");
        // Navigate to the Home Page
        navigate("/account");
      });
  };

  // Function to check if a user with the provided userId exists
  const checkUserExists = (userId) => {
    const usersCollectionRef = collection(db, "users");

    // Query the users collection for documents with matching userId
    const q = query(usersCollectionRef, where("userId", "==", userId));

    // Execute the query and handle the result using then()
    return getDocs(q)
      .then((querySnapshot) => {
        // Check if any documents match the query
        if (!querySnapshot.empty) {
          // User with the provided userId exists
          return true;
        } else {
          // User with the provided userId does not exist
          return false;
        }
      })
      .catch((error) => {
        alert(
          "An error occured while checking your existence. Please sign in again."
        );
        // We set the active link to the account page to allow user to retry signing in
        setActiveNavLink("Account");
        // Navigate to the Home Page
        navigate("/account");
      });
  };

  // Function to handle user sign-in
  const handleSignIn = (user) => {
    checkUserExists(user.uid)
      .then((exists) => {
        if (exists) {
          // We get user from db and set state to this user
          getUser(user.uid).then(() => {
            // We set the active link to the Home page as we try to add user to our db
            setActiveNavLink("Home");
            // Navigate to the Home Page
            navigate("/");
          });
        } else {
          // User does not exist, create new user record
          addUser(user).then(() => {
            // We set the active link to the Home page as we try to add user to our db
            setActiveNavLink("Home");
            // Navigate to the Home Page
            navigate("/");
          });
        }
      })
      .catch((error) => {
        alert("An error occured while signing you in. Please sign in again.");
        // We set the active link to the account page to allow user to retry signing in
        setActiveNavLink("Account");
        // Navigate to the Home Page
        navigate("/account");
      });
  };

  // Sign in with Google
  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        handleSignIn(data.user);
      })
      .catch((error) => {
        alert("An error occured while signing you in. Please sign in again.");
        // We set the active link to the account page to allow user to retry signing in
        setActiveNavLink("Account");
        // Navigate to the Home Page
        navigate("/account");
      });
  };

  useEffect(() => {
    // setUser(JSON.parse(localStorage.getItem("user")));
    /* If user is signed in, we display user details */
    user && navigate("/user_details");
  });

  // We navigate to the Home page after succesfully adding or retrieving the user
  useEffect(() => {}, [{ ...user }]);

  return (
    <div className="md:w-1/2 mx-auto my-[50px] text-center">
      {/* We display the Sig In button if user isn't signed in */}
      <div>
        <Button
          onClick={handleClick}
          variant="outlined"
          color="warning"
          size="large"
          endIcon={<GoogleIcon fontSize="small" color="info" />}
        >
          {loadingUser ? "Loading..." : "Sign in with Google"}
        </Button>
      </div>
    </div>
  );
}

export default Account;
